<cp-rp-card
  [title]="displayTitle()"
  [showBorder]="showBorder()"
  [goToPage]="{ page: goToPage(), measure: measureId }">
  @if (showBucketLabel()) {
    <h5 class="text-center mb-3">
      {{ measure.label }}
    </h5>
  }
  <div
    [class.horizontal]="
      breakpoints.current().largerThan('small') &&
      orientation() === 'horizontal' &&
      showBuckets()
    ">
    @if (type() === 'full') {
      <cp-rp-donut-chart
        [measureId]="measureId"
        [showScoreLabel]="showScoreLabel()"
        [showScoreValue]="showScoreValue()" />
    } @else {
      <cp-rp-half-donut-chart
        [measureId]="measureId"
        [showScoreLabel]="showScoreLabel()" />
    }

    @if (
      (type() === 'half' &&
        component.hasExplicitAttribute('showBuckets') &&
        showBuckets()) ||
      (type() === 'full' && showBuckets())
    ) {
      <div class="d-flex flex-column justify-content-center mx-auto w-md-80">
        @for (
          bucket of scoringBuckets | orderBy: '-ceiling';
          track bucket.scoringBucketId
        ) {
          <div
            class="d-flex justify-content-center pt-1 mx-3 mx-md-0 pb-1 scoring-buckets">
            <div class="me-auto">{{ bucket.name }}</div>
            <div class="text-end">
              {{ bucket.floorFormatted }} - {{ bucket.ceilingFormatted }}
            </div>
          </div>
        }
      </div>
    }
  </div>
</cp-rp-card>
