<div class="svg-wrapper mb-4">
  @if (showScoreValue()) {
    <span class="score" [class.pb-5]="scoreBucket() && showScoreLabel()">
      {{ score()?.formattedScore }}
    </span>
  }
  @if (scoreBucket() && showScoreLabel()) {
    <span
      class="category"
      [ngClass]="{
        'pt-5': showScoreValue(),
        'fs-4': !showScoreValue(),
      }">
      {{ scoreBucket()?.name }}
    </span>
  }
  <svg viewBox="0 0 234 234">
    <circle class="bg" cx="50%" cy="50%" r="48%" />
    <circle
      class="meter-1"
      cx="50%"
      cy="50%"
      r="48%"
      [ngStyle]="{
        strokeDasharray: chartConfig().progress * 220 + '% 720',
      }" />
  </svg>
</div>
