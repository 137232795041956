<canvas
  height="141"
  width="190"
  baseChart
  [datasets]="doughnutChartDatasets()"
  [options]="doughnutChartOptions"
  class="mx-auto"
  type="doughnut">
</canvas>

@if (showScoreLabel()) {
  <div class="text-center chart-label">{{ scoreBucket()?.name }}</div>
}
