import { ReportComponentDef } from './shared/models/components/report-component-def';
import { HeaderComponent } from './shared/public-components/header/header.component';
import { PageMenuComponent } from './shared/public-components/page-menu/page-menu.component';
import { PageComponent } from './shared/public-components/page/page.component';
import { LabelChartComponent } from './shared/public-components/label-chart/label-chart.component';
import { TemplateComponent } from './shared/public-components/template/template.component';
import { LayoutComponent } from './shared/public-components/layout/layout.component';
import {
  largerOrEqualTo,
  pattern,
  validValues,
} from './shared/models/components/components-attribute-validators';
import { AnswersComponent } from './shared/public-components/answers/answers.component';
import { MeasureComponent } from './shared/public-components/measure/measure.component';
import { TagComponent } from './shared/public-components/tag/tag.component';
import { MeasureChartComponent } from './shared/public-components/measure-chart/measure-chart.component';
import { InfoComponent } from './shared/public-components/info/info.component';
import { DividerComponent } from './shared/public-components/divider/divider.component';
import { ReportVimeoPlayerComponent } from './shared/public-components/report-vimeo-player/report-vimeo-player.component';
import { AccordionToggleButtonComponent } from './shared/public-components/accordion-toggle-button/accordion-toggle-button.component';

export const reportComponents: ReportComponentDef[] = [
  {
    tagName: 'cp-header',
    attributes: {
      name: 'boolean',
      email: 'boolean',
      phone: 'boolean',
    },
    component: HeaderComponent,
  },
  {
    tagName: 'cp-page-menu',
    component: PageMenuComponent,
  },
  {
    tagName: 'cp-page',
    component: PageComponent,
    attributes: {
      name: 'string',
      open: {
        type: 'boolean',
        defaultValue: false,
      },
    },
  },
  {
    tagName: 'cp-label-chart',
    component: LabelChartComponent,
    attributes: {
      orientation: {
        type: 'string',
        validator: validValues('horizontal', 'vertical'),
      },
      goToPage: 'string',
      showBucketLabel: {
        type: 'boolean',
        defaultValue: false,
      },
      showTitle: {
        type: 'boolean',
        defaultValue: true,
      },
      title: 'string',
      showBorder: {
        type: 'boolean',
        defaultValue: true,
      },
      showBuckets: {
        type: 'boolean',
        defaultValue: true,
      },
      type: {
        type: 'string',
        validator: validValues('full', 'half'),
      },
      showScoreLabel: {
        type: 'boolean',
        defaultValue: true,
      },
      showScoreValue: {
        type: 'boolean',
        defaultValue: true,
      },
    },
  },
  {
    tagName: 'cp-template',
    component: TemplateComponent,
  },
  {
    tagName: 'cp-layout',
    component: LayoutComponent,
    attributes: {
      columns: {
        type: 'string',
        validator: pattern(/^\d+( \d+)*$/),
        defaultValue: '1',
      },
      mobileColumns: {
        type: 'string',
        validator: pattern(/^\d+( \d+)*$/),
        defaultValue: '1',
      },
    },
  },
  {
    tagName: 'cp-answers',
    component: AnswersComponent,
    attributes: {
      collapsed: {
        type: 'boolean',
        defaultValue: false,
      },
    },
  },
  {
    tagName: 'cp-measure',
    component: MeasureComponent,
    attributes: {
      description: 'string',
      showSelf: {
        type: 'boolean',
        defaultValue: false,
      },
      showChildren: 'boolean',
      showChart: 'boolean',
      showChartValue: 'boolean',
      showChartTails: 'boolean',
      chartTailStart: 'string',
      chartTailEnd: 'string',
      title: 'string',
      collapsed: {
        type: 'boolean',
        defaultValue: false,
      },
    },
  },
  {
    tagName: 'cp-tag',
    component: TagComponent,
    attributes: {
      tag: 'string',
      display: {
        type: 'string',
        defaultValue: 'auto',
        validator: validValues('list', 'paragraph', 'inline', 'auto'),
      },
      listStyle: {
        type: 'string',
        defaultValue: 'disc',
        validator: validValues(
          'disc',
          'lower-alpha',
          'upper-alpha',
          'lower-roman',
          'upper-roman',
          'number',
        ),
      },
      label: 'string',
      maxItems: {
        type: 'number',
        defaultValue: 100,
        validator: largerOrEqualTo(0),
      },
    },
  },
  {
    tagName: 'cp-measure-chart',
    component: MeasureChartComponent,
    attributes: {
      showSelf: {
        type: 'boolean',
        defaultValue: false,
      },
      goToPage: 'string',
      title: 'string',
      showChildren: 'boolean',
      showValue: 'boolean',
      showTails: 'boolean',
      tailStart: 'string',
      tailEnd: 'string',
      legend: {
        type: 'string',
        defaultValue: 'auto',
        validator: validValues('auto', 'score', 'target', 'none'),
      },
      targetMeasure: 'string',
      showBorder: {
        type: 'boolean',
        defaultValue: false,
      },
    },
  },
  {
    tagName: 'cp-item',
  },
  {
    tagName: 'cp-info',
    component: InfoComponent,
    attributes: {
      showMeasureCount: {
        type: 'boolean',
        defaultValue: false,
      },
    },
  },
  {
    tagName: 'cp-divider',
    component: DividerComponent,
  },
  {
    tagName: 'cp-vimeo',
    component: ReportVimeoPlayerComponent,
    attributes: {
      src: {
        type: 'string',
        defaultValue: '',
      },
      showCaptions: {
        type: 'boolean',
        defaultValue: true,
      },
    },
  },
  {
    tagName: 'cp-accordion-toggle',
    component: AccordionToggleButtonComponent,
    attributes: {
      label: 'string',
      showLabel: 'boolean',
      labelPosition: {
        type: 'string',
        defaultValue: 'start',
        validator: validValues('start', 'end'),
      },
    },
  },
];
